<template>
  <v-dialog :value="value" class="dialog" width="1200">
    <v-card color=#fafafa>
      <v-toolbar flat dark color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{`Copy - ${itemCopy.OrderCode} - ${itemCopy.Description}`}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container class="ma-0 pa-6">
        <v-row dense>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" outlined label="Item Code" v-model="item.OrderCode"
              @keydown.enter="getByItemCode" autocomplete="off">
              <template v-slot:append>
                <v-btn small icon>
                  <v-icon @click="getByItemCode">mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field dense readonly background-color="#fff" outlined label="Pack" :value="item.Pack"
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense readonly background-color="#fff" outlined label="Size" :value="item.Size"
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense readonly background-color="#fff" outlined label="UPC" :value="item.UPC"
              autocomplete="off" />
          </v-col>
          <v-col cols="5">
            <v-text-field dense readonly background-color="#fff" outlined label="Item Description"
              :value="item.Description" autocomplete="off" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" outlined class="input" placeholder="0.00" type="number"
              step="0.01" min="0" prefix="$" label="Case Cost" v-model="itemCopy.CaseCost" @change="formatCurrencyInput('CaseCost', itemCopy.CaseCost)"
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="AMAP" v-model="itemCopy.AMAP" @change="formatCurrencyInput('AMAP', itemCopy.AMAP)"
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="Ad BB" v-model="itemCopy.AdBB"
              @change="formatCurrencyInput('AdBB', itemCopy.AdBB)" autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="TPR BB" v-model="itemCopy.TPRBB"
              @change="formatCurrencyInput('TPRBB', itemCopy.TPRBB)" autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="Ad Scan" v-model="itemCopy.AdScan"
              @change="formatCurrencyInput('AdScan', itemCopy.AdScan)" autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="TPR Scan" v-model="itemCopy.TPRScan"
              @change="formatCurrencyInput('TPRScan', itemCopy.TPRScan)" autocomplete="off" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="ePay Ad" v-model="itemCopy.ePayAd"
              @change="formatCurrencyInput('ePayAd', itemCopy.ePayAd)" autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="ePay TPR" v-model="itemCopy.ePayTPR"
              @change="formatCurrencyInput('ePayTPR', itemCopy.ePayTPR)" autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="EBA" v-model="itemCopy.EBA" @change="formatCurrencyInput('EBA', itemCopy.EBA)"
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" max="1" outlined label="Compete" v-model="itemCopy.Compete"
              @change="formatCurrencyInput('Compete', itemCopy.Compete)" autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" placeholder="0.00" prefix="$" type="number" step="0.01"
              min="0" outlined label="PCAF" v-model="itemCopy.PCAF" @change="formatCurrencyInput('PCAF', itemCopy.PCAF)"
              autocomplete="off" />
          </v-col>
          <v-col cols="2">
            <v-text-field dense background-color="#fff" outlined label="Deal ID" v-model="itemCopy.DealID"
              autocomplete="off" />
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-toolbar color="#fafafa" flat>
        <v-spacer></v-spacer>
        <v-btn height="40" color="#00b24a" class="white--text ml-2" @click="copy">
          Add<v-icon class="ml-1" dense>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'ContractItemCopy',

  mixins: [notification],

  props: ['value', 'selectedItem', 'contract', 'getContract'],

  data () {
    return {
      item: {}
    }
  },

  computed: {
    itemCopy () {
      return cloneDeep(this.selectedItem)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          for (const key in this.itemCopy.Item) {
            this.itemCopy[key] = this.itemCopy.Item[key]
          }
          const valuesToFormat = {
            AMAP: this.itemCopy.AMAP,
            AdBB: this.itemCopy.AdBB,
            AdScan: this.itemCopy.AdScan,
            Compete: this.itemCopy.Compete,
            EBA: this.itemCopy.EBA,
            PCAF: this.itemCopy.PCAF,
            TPRBB: this.itemCopy.TPRBB,
            TPRScan: this.itemCopy.TPRScan,
            ePayAd: this.itemCopy.ePayAd,
            ePayTPR: this.itemCopy.ePayTPR,
            CaseCost: this.itemCopy.CaseCost
          }
          for (const key in valuesToFormat) {
            this.formatCurrencyInput(key, valuesToFormat[key])
          }
        }
      }
    },

    item: {
      immediate: true,
      deep: true,
      handler () {
        this.contract.ContractItems.forEach(item => {
          if (item.Item.OrderCode === this.item.OrderCode) {
            this.notify('error', 'Item Already Exists On Contract')
          }
        })
      }
    }
  },

  methods: {
    formatCurrencyInput (key, value) {
      this.itemCopy[key] = ((value * 100) / 100).toFixed(2)
      this.$forceUpdate()
    },

    async getByItemCode () {
      await this.$contracts.getByItemCode(this.item.OrderCode, this.contract.Supplier.ID)
        .then(response => {
          this.item = {
            OrderCode: response[0].orderCode,
            ItemCode: response[0].itemCode,
            Pack: response[0].pack,
            Size: response[0].size,
            UPC: response[0].UPC,
            Description: response[0].description
          }
        }).catch(() => {
          this.notify('error', 'Item Not Available')
        })
    },

    async copy () {
      this.itemCopy.Item = {
        OrderCode: this.item.OrderCode,
        ItemCode: this.item.ItemCode,
        Pack: this.item.Pack,
        Size: this.item.Size,
        UPC: this.item.UPC,
        Description: this.item.Description,
        CaseCost: this.itemCopy.CaseCost
      }
      await this.$contracts.saveItem(this.itemCopy)
        .then(() => {
          this.notify('success', 'Item Successfully Added')
        }).catch(() => {
          this.notify('error', 'Item Not Added')
        })
      this.getContract()
      this.item = {}
    }
  }
}
</script>
